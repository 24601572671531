module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Irving Park United Methodist Church | Greensboro, NC","short_name":"IPUMC Greensboro","start_url":"/","background_color":"#fff","theme_color":"#344082","display":"standalone","icon":"src/images/ipumc-icon-square.jpg","include_favicon":false,"icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"41f881b600b281340fc62b4282d7cffc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
